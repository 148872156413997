<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <app-view-toolbar
        :title="title"
        :buttons="toolbarButtons"
        :dropdownMenuItems="dropdownMenuItems"
        @onButton="onToolbarClick"
        @onButtonMenuItem="onToolbarClick"
        @onDropdownMenuItem="onToolbarClick"
      ></app-view-toolbar>
    </v-container>
    
    <v-container class="py-2 px-6">
      <v-subheader class="justify-space-between">
        <v-switch
          v-model="reorderEnabled"
          dense
          label="Sortera lista"
          @change="onSavePerformerRank">
        </v-switch>
        Alla band
        <v-btn text class="mr-2" small plain @click="onOpenCreatePerformer">
          <v-icon left>mdi-plus</v-icon>
          Nytt band
        </v-btn>
      </v-subheader>
      <v-data-table
        :headers="tableData.headers"
        :items="tableData.items"
        :items-per-page="itemsPerPage"
        no-data-text="Inga band upplagda"
        no-results-text="Inga band hittades"
        :page.sync="page"
        @click:row="onOpenEditPerformer"
        @page-count="pageCount = $event"
        hide-default-footer
        class="click-table"
        locale="sv-SE">
        <template v-if="reorderEnabled" #body="props">
          <draggable
            tag="tbody"
            :list="props.items"
            :disabled="!reorderEnabled"
            @end="onSetPerformerRank">
            <template>
              <tr v-for="(item, index) in props.items" :key="index">
                <td>{{item.epr_str_name}}</td>
                <td>{{item.epr_dat_publish}}</td>
              </tr>
            </template>
          </draggable>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
 
import { onMounted, ref, reactive, watch } from '@vue/composition-api';
import { DateTime } from 'luxon';
import draggable from 'vuedraggable';
import useCore from '../../helpers/core';
import ViewToolbar from '../../components/ViewToolbar.vue';

export default {
  name: 'eventDashboard',
  props: {
    evnId: {
      type: Number,
      required: false,
    },
  },
  components: {
    draggable,
    'app-view-toolbar': ViewToolbar,
  },
  setup(props) {
    const { callApi, showMessage } = useCore();
    const router = require('@/router/router').default;

    // Event
    const dataLoaded = ref(false);
    const selectedEvn = ref(null);
    const reorderEnabled = ref(false);
    const rankData = ref([]);
    const tableData = reactive({
      headers: [
        { text: 'Namn', value: 'epr_str_name' },
        { text: 'Publiceringsdatum', value: 'epr_dat_publish' },
      ],
      items: [],
    });

    const mapEpr = (epr) => {
      const datePublished = DateTime.fromISO(epr.epr_dat_publish);
      return {
        ...epr,
        epr_dat_publish: datePublished.isValid ? datePublished.toFormat('yyyy-LL-dd HH:mm') : '',
      };
    }

    const onSetPerformerRank = ({ newIndex, oldIndex }) => {
      const element = rankData.value.splice(oldIndex, 1)[0];
      rankData.value.splice(newIndex, 0, element);
      rankData.value = rankData.value.map(({ epr_id }, index) => ({ epr_id, epr_int_rank: index+1}));
    };
    const onSavePerformerRank = (startReorder) => {
      // starting reorder? Skip since we are just interrested when it's done
      if (startReorder) { return; }
      // Reorder done; Update backend with new ranks
      callApi({ method: 'put', path: `/cms/events/${selectedEvn.value.evn_id}/performers/ranks`, data: rankData.value })
        .then((res) => {
          showMessage(`Ny sortering har sparats`);
          tableData.items = res.map(mapEpr);
        })
        .catch((err) => {
          return err;
        });
    };
    
    const onChangeEvent = ({ id }) => router.push({ name: 'eventDashboard', params: { evnId: id }});
    const onOpenEventList = () => router.push({ name: 'event' });
    const onOpenCreateEvent = () => router.push({ name: 'eventPut', params: { id: 0 }});
    const onOpenEditEvent = () => router.push({ name: 'eventPut', params: { id: selectedEvn.value.evn_id } });
    const onOpenStageList = () => router.push({ name: 'eventStage' });
    const onOpenEventPerformanceList = () => router.push({ name: 'eventPerformance', params: { evnId: selectedEvn.value.evn_id } });
    const onOpenCreatePerformer = () => router.push({ name: 'eventPerformerPut', params: { evnId: selectedEvn.value.evn_id, id: 0 } });
    const onOpenEditPerformer = (item) => router.push({ name: 'eventPerformerPut', params: { evnId: selectedEvn.value.evn_id, id: item.epr_id } });

    const dropdownMenuItems = [
      { type: 'subheader', name: 'Event' },
      { type: 'button', icon: 'plus', name: 'Nytt event', fn: onOpenCreateEvent },
      { type: 'button', icon: 'pencil-outline', name: 'Redigera event', fn: onOpenEditEvent },
      { type: 'button', icon: 'pencil-outline', name: 'Redigera spelningslotter', fn: onOpenEventPerformanceList },
      { type: 'button', name: 'Alla events', fn: onOpenEventList },
      { type: 'subheader', name: 'Övrigt' },
      { type: 'button', name: 'Scener', fn: onOpenStageList },
    ];
    const toolbarButtons = ref([
      { type: 'menu', icon: 'swap-horizontal', name: 'Byt event', menuItems: [] },
    ]);
    const onToolbarClick = (item) => item.fn(item);

    const title = ref('Events');

    // Changing Event
    const onLoadEvent = (evnId) => {
      dataLoaded.value = false;
      const path = evnId
        ? `/cms/events/${evnId}/dashboard`
        : '/cms/events/dashboard';
      callApi({ method: 'get', path })
        .then((res) => {
          dataLoaded.value = true;
          rankData.value = res.eprs.map((epr, index) => ({ ...epr, epr_int_rank: index+1 }));
          tableData.items = res.eprs.map(mapEpr);
          // Set title
          selectedEvn.value = res.selectedEvn;
          title.value = res.selectedEvn.evn_str_name;
          // Remove selected Evn from dropdown
          toolbarButtons.value[0].menuItems = res.evns.reduce((res, evn) => {
            if (evn.evn_id === evnId) { return res; }
            return [...res, { type: 'button', name: evn.evn_str_name, id: evn.evn_id, fn: onChangeEvent }]
          }, []);
        })
        .catch((err) => {
          return err;
        });
    };
    watch(() => props.evnId, evnId => onLoadEvent(evnId));

    // Lifecycle-methods
    const doOnMounted = () => {
      onLoadEvent(props.evnId);
    };
    onMounted(doOnMounted);

    return {
      dataLoaded,
      tableData,
      dropdownMenuItems,
      title,
      toolbarButtons,
      onToolbarClick,
      onOpenEditPerformer,
      onOpenCreatePerformer,

      reorderEnabled,
      onSetPerformerRank,
      onSavePerformerRank,

      page: 1,
      pageCount: 0,
      itemsPerPage: 150,
    };
  },
};
</script>

<style>

</style>